<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container flexs">
            <div class="modal-header closes">
              <h1>Hello, {{ user.name }}</h1>
              <button
                class="modal-default-button"
                @click="$emit('close')"
                v-if="close"
              >
                X
              </button>
            </div>
            <div class="modal-body content">
              <transition name="scale" mode="out-in" tag="div">
                <Slides />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import Slides from "./splideMobile.vue";
export default {
  props: ['openMenu'],
  components: {
    Slides,
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  data() {
    return {
      close: false
    }
  }
};
</script>

<style scoped>
.buttons {
  display: flex;
  justify-content: space-around;
}
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  margin: 0px auto;
  background-color: #2474b2;
  color: #fff;
  border-radius: 2px;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h1 {
  margin-top: 10px;
  margin-left: 10px;
}

.modal-default-button {
  margin-right: 10px;
  float: right;
}

.flexs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 15px;
  width: 350px;
  height: 500px;
}

.btn {
  width: 15px;
}

.closes {
  align-items: center;
  width: 100%;
}

.closes button {
  height: 15px;
  width: 15px;
  font-size: 16px;
  color: #fff;
  background: none;
  border: none;
  outline: none;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  font-size: 16px;
}
</style>