<template>
  <div class="wrapper">
    <splide
      :slides="imagens"
      :options="options"
      @splide:moved="moved"
      class="box"
    >
      <splide-slide v-for="(item, index) in imagens" :key="index">
        <!-- <a href="dasd" target="blanck" title="eweq">  -->
          <!-- <img :src="slide" alt="slide.alt" /> -->
           <a v-if="item.link" :href="item.link" target="blanck" :title="item.link"> <img class="images" :src="getImg(item.image)" alt=""/> </a>
            <img v-else class="images" :src="getImg(item.image)" alt=""/>
        <!-- </a> -->
      </splide-slide>
    </splide>
  </div>
</template>

<script>
import HTTP from '@/api_system'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'
export default {
  //   props: ["slides"],
  components: {
    Splide,
    SplideSlide
  },
  data () {
    return {
      options: {
        rewind: false,
        width: '800px',
        cover: true,
        height: '450px',
        perPage: 1,
        heightRatio: 30
      },
      imagens: []
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      let user = this.$store.getters.getUser;
      HTTP.get(`services/app/Notice/GetDashBoardNotices`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then((response) => {
          this.img = response.data.result[0].image
          let res = response.data.result

          res.forEach((item) => {
            if ((item.profile == user.profile || item.profile == 0) && item.type == 0) {
              let imgs = this.getImg(item.image)
              this.imagens.push(item)
            }
          })
        })
        .catch((err) => console.log(err))
    },
    moved (splide, newIndex) {
      let index = this.imagens.length
      if (newIndex + 1 === index) this.$parent.close = true
    }
  },
  watch: {
    imagens: function (value) {
      if (value.length === 1) this.$parent.close = true
    }
  }
}
</script>

<style scoped>
img {
  min-width: 800px;
  max-height: 450px;
  max-width: 800px;
  /* border-radius: 25px; */
}

.box {
  box-shadow: 1px -1px 20px 2px #000;
}
</style>
